import { App } from "./App.js";
import $ from 'jquery';
const app = new App();

app.loadAssets().then(app.init);


$(() => {
    $('.bar .item').on('mouseenter', function (e) {
        e.stopPropagation()
        e.preventDefault()
        let key = $(this).attr('data-key')
        app.onHover(key)
    })
    $('.bar .item').on('mouseleave', function (e) {
        e.stopPropagation()
        e.preventDefault()
        app.allHide()
    })
    $('.bar .item').on('click', function () {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            app.resetPosition()
        } else {
            $(this).addClass('active').siblings().removeClass('active');
            let key = $(this).attr('data-key')
            let url = $(this).attr('data-url')
            app.setPosition(key, url ? () => { window.location.href = url } : null);
            if ($(this).hasClass('sm-btn')) {
                $('.dialog1').fadeIn()
                $('.side').fadeOut()
                $('.bar').fadeOut()
                $('.sys-name').fadeOut()
            }
        }
    })
    $('.logo').on('click', function () {
        window.location.reload()
    })
    $('.ap-btn').on('click', function () {
        if ($(this).hasClass('active')) {
            app.resetPosition()
            $(this).removeClass('active');
            $('.dialog').fadeOut()
            $('.bar').fadeIn()
            $('.sys-name').fadeIn()
        } else {
            app.setPosition('ap')
            $(this).addClass('active');
            $('.dialog').fadeIn()
            $('.bar').fadeOut()
            $('.sys-name').fadeOut()
        }
    })
    $('.ed-btn').on('click', function () {
        window.location.href = 'https://studio.chubbfs.com/office/'
    })

    $('.dialog1 .close').on('click', function () {
        app.resetPosition()
        $('.dialog1').fadeOut()
        $('.side').fadeIn()
        $('.bar').fadeIn()
        $('.sys-name').fadeIn()
    })
})