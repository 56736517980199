import $ from 'jquery';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { FontLoader } from 'three/addons/loaders/FontLoader.js';
// import { Noise } from 'noisejs';
// const noise = new Noise(Math.random());
const buildings = {
    dc: 'transform5',
    co: 'mainDesirefxFBXASC046me_NeoShanghai_BldgLG_E_Roofs',
    sm: 'transform4'
}
const objPositions = {
    co: new THREE.Vector3(
        -7.221536848849406, 2.3200232465815054, -6.207587023148181),
    dc: new THREE.Vector3(
        4.709053121423374, 1.0727538284779246, -5.831589455549088),
    sm: new THREE.Vector3(

        0.7793668867572907,
        1.1280849501080417,
        1.3413296230877367),
    ap: new THREE.Vector3(
        -8.658439674133657, 3.7299442462776553, 3.6158600241840606)

}
const tgtPositions = {
    co: new THREE.Vector3(
        -7.296191083443301, 0.9293291546192647, 2.3981247165241646),
    dc: new THREE.Vector3(
        4.614179331278739, 0.5833012600515565, -1.3842977966097738),
    sm: new THREE.Vector3(
        1.0340163529508413, 1.0545956302464803, 1.5880348454632205),
    ap: new THREE.Vector3(
        -1.0620420171465452, -0.05612844384738193, -1.982884844763902)
}
const particleCount = 1000; // 粒子数量
export class App {
    constructor() {
        this.initRenderer()
        this.initScene()
        this.initCamera()
        this.initControls()
        this.loadModel()
        // this.globalParticle()
        this.raycaster = new THREE.Raycaster();
        this.mouse = new THREE.Vector2();
        this.raycaster1 = new THREE.Raycaster();
        this.mouse1 = new THREE.Vector2();

        this.debouncedCheckHover = this.debounce(this.checkHover, 32).bind(this);
        window.addEventListener('mousemove', (event) => {
            if (event.target.tagName === 'CANVAS') {
                this.mouse1.x = (event.clientX / window.innerWidth) * 2 - 1;
                this.mouse1.y = -(event.clientY / window.innerHeight) * 2 + 1;
                this.debouncedCheckHover();
            }
        });
        // window.addEventListener('click', this.onClick.bind(this));
        window.addEventListener('click', this.onClick.bind(this));
        this.init = this.init.bind(this);
        this.tick = this.tick.bind(this);
        this.onHover = this.onHover.bind(this);
        this.allHide = this.allHide.bind(this);
        this.startTime = Date.now()
        this.arrows = {}
        this.texts = {}
        this.bgs = {}
        this.circles = {}
        this.buildings = {}
    }
    onHover(key) {
        this.allHide()
        let prefix = buildings[key];
        let arrow = this.arrows[prefix];
        let text = this.texts[prefix];
        let bg = this.bgs[prefix];
        arrow.isRuning = true;
        text.visible = true;
        bg.visible = true;
        if (!this.buildings[prefix])
            this.buildings[prefix] = this.model.getObjectByName(prefix)
    }
    allHide() {
        for (let key in buildings) {
            let prefix = buildings[key];
            let arrow = this.arrows[prefix];
            let text = this.texts[prefix];
            let bg = this.bgs[prefix];
            if (arrow)
                arrow.isRuning = false;
            if (text)
                text.visible = false;
            if (bg)
                bg.visible = false;
        }
    }
    init() {
        this.tick();
    }
    arrowAnimate() {
        if (!this.model) return
        // 获取当前时间
        const elapsedTime = Date.now() - this.startTime;

        // 对每一个箭头进行操作
        this.model.children.forEach(child => {
            if (child.name.startsWith('arrow')) {
                if (!child.isRuning) return
                // 让箭头上下移动
                const amplitude = 0.1; // 振幅
                const frequency = 0.005; // 频率
                child.position.y = child.initialPosition.y + amplitude * Math.sin(frequency * elapsedTime);
            } else if (child.name.endsWith('Circle')) {
                // 改变圆环的大小
                // const v = 0.015;
                // child.scale.x += v;
                // child.scale.y += v;
                // child.material.opacity -= v / 2;
                // // 当圆环放大到一定程度后，重置圆环的大小
                // if (child.scale.x >= 2) {
                //     child.scale.set(0, 0, 0);
                //     child.material.opacity = 1;
                // }

                // child.material.opacity = 1 - Math.abs(Math.sin(elapsedTime * 0.003));
            }
        });
    }
    addArrow(name, word, offset = { x: 0, y: 0, z: 0 }) {
        let obj = this.model.getObjectByName(name)
        const box = new THREE.Box3().setFromObject(obj);
        // 获取 boundingBox 的中心点
        const center = box.getCenter(new THREE.Vector3());
        // 获取对象的顶部中心点
        const topCenter = new THREE.Vector3(
            center.x + offset.x,
            box.max.y + offset.y,
            center.z + offset.z
        );

        const arrowGeometry = new THREE.ConeGeometry(0.1, 0.3, 4);
        const arrowMaterial = new THREE.MeshLambertMaterial({ color: 0x36A0FB });
        const arrow = new THREE.Mesh(arrowGeometry, arrowMaterial);
        arrow.castShadow = true;
        arrow.receiveShadow = true;
        arrow.position.copy(topCenter);
        // 旋转箭头让它朝下
        arrow.rotation.x = Math.PI;
        arrow.name = `arrow_${name}`;
        arrow.initialPosition = topCenter.clone();
        arrow.isRuning = false;
        this.model.add(arrow);
        // arrow.visible = false;
        this.arrows[name] = arrow

        this.loadFont(topCenter, word, name)

        // const loader = new THREE.FontLoader();
        // loader.load('helvetiker_regular.typeface.json', function (font) {
        //     // 创建文本几何体
        //     const textGeometry = new THREE.TextGeometry('Hello', {
        //         font: font,
        //         size: 0.2,
        //         height: 0.02,
        //     });

        //     // 创建文本材质
        //     const textMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });

        //     // 创建文本并添加到场景中
        //     const text = new THREE.Mesh(textGeometry, textMaterial);
        //     text.position.set(arrow.position.x, arrow.position.y + 0.2, arrow.position.z);
        //     this.model.add(text);
        // });
        // // 创建一个圆形的几何体
        // const circleGeometry = new THREE.RingGeometry(0.1, 0.12, 32);
        // const circleMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00, side: THREE.DoubleSide, transparent: true, opacity: 1 });
        // const circle = new THREE.Mesh(circleGeometry, circleMaterial);
        // circle.position.copy(topCenter);
        // circle.position.y -= 0.2;
        // circle.rotation.x = Math.PI / 2;
        // circle.name = name + '_Circle';
        // circle.initialPosition = topCenter.clone();
        // // circle.visible = false;
        // this.circles[name] = circle
        // this.model.add(circle);
    }
    loadFont(position, word, name) {
        if (!this.fontLoader)
            this.fontLoader = new FontLoader();
        this.fontLoader.load('Arial_Regular.json', (font) => {
            // 使用字体生成形状
            const shapes = font.generateShapes(word, 0.2);

            // 创建文本几何体
            const textGeometry = new THREE.ShapeGeometry(shapes);
            // 计算文本的边界
            textGeometry.computeBoundingBox();

            // 计算文本的中心
            const xMid = - 0.5 * (textGeometry.boundingBox.max.x - textGeometry.boundingBox.min.x);
            const yMid = - 0.5 * (textGeometry.boundingBox.max.y - textGeometry.boundingBox.min.y);

            // 将文本的位置设置为中心的负值，使文本在坐标上居中
            textGeometry.translate(xMid, yMid, 0);

            // 创建文本材质
            const textMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });

            // 创建文本并添加到场景中
            const text = new THREE.Mesh(textGeometry, textMaterial);
            // 创建一个背景
            const backgroundGeometry = new THREE.PlaneGeometry((textGeometry.boundingBox.max.x - textGeometry.boundingBox.min.x) * 1.1, (textGeometry.boundingBox.max.y - textGeometry.boundingBox.min.y) * 1.5)
            const backgroundMaterial = new THREE.MeshBasicMaterial({ color: 0x005EAE, doubleside: true });
            const backgroundMesh = new THREE.Mesh(backgroundGeometry, backgroundMaterial);
            text.rotation.y = Math.PI
            text.position.set(position.x, position.y + 0.5, position.z);
            text.visible = false
            this.texts[name] = text;
            backgroundMesh.rotation.y = Math.PI
            backgroundMesh.position.set(position.x, position.y + 0.5, position.z + 0.001);
            backgroundMesh.visible = false
            this.bgs[name] = backgroundMesh;
            this.model.add(text);
            this.model.add(backgroundMesh);
        });
    }
    loadModel() {
        this.gltfLoader = new GLTFLoader();
        this.gltfLoader.load('./ditu.glb', (gltf) => {
            this.model = gltf.scene;
            this.addArrow(buildings.dc, 'Data Center')
            this.addArrow(buildings.co, 'Commercial Office', { x: 0.1, y: -.4, z: -0.4 })
            this.addArrow(buildings.sm, 'Shopping Mall', { x: 0, y: 0.3, z: 0 })
            document.getElementById('loading').style.display = 'none';
            this.scene.add(this.model);
            // this.roadParticles()
            this.addCar()
        });
    }
    animateRotation(model, targetRotation, duration, callback) {
        const startRotation = model.rotation.clone();
        const endRotation = new THREE.Euler(0, targetRotation, 0);
        const startQuaternion = new THREE.Quaternion().setFromEuler(startRotation);
        const endQuaternion = new THREE.Quaternion().setFromEuler(endRotation);
        const startTime = performance.now();

        function animate() {
            const elapsed = performance.now() - startTime;
            const t = Math.min(elapsed / duration, 1);

            model.quaternion.copy(startQuaternion).slerp(endQuaternion, t);
            model.rotation.setFromQuaternion(model.quaternion);

            if (t < 1) {
                requestAnimationFrame(animate);
            } else {
                callback && callback()
            }
        }

        animate();
    }

    resetModelY() {
        this.animateRotation(this.model, this.previousRotationY, 1000);
    }
    setModelY() {
        this.previousRotationY = this.model.rotation.y;
        this.animateRotation(this.model, 0, 1000);
    }
    animatePosition(position, targetPosition, duration, callback) {
        const startPosition = position.clone();
        const deltaPosition = targetPosition.clone().sub(startPosition);
        const startTime = performance.now();

        function animate() {
            const elapsed = performance.now() - startTime;
            const t = Math.min(elapsed / duration, 1);

            position.copy(startPosition).addScaledVector(deltaPosition, t);

            if (t < 1) {
                requestAnimationFrame(animate);
            } else {
                callback && callback()
            }
        }

        animate();
    }
    resetPosition() {
        this.noRotate = false
        this.resetModelY()
        this.animatePosition(this.controls.object.position, this.previousPosition, 1000);
        this.animatePosition(this.controls.target, this.previousTarget, 1000);
        this.previousPosition = null
        this.previousTarget = null
    }
    setPosition(key, callback) {
        this.noRotate = true
        if (!this.previousPosition)
            this.previousPosition = this.controls.object.position.clone();
        if (!this.previousTarget)
            this.previousTarget = this.controls.target.clone();
        this.animatePosition(this.controls.object.position, objPositions[key], 1000);
        this.animatePosition(this.controls.target, tgtPositions[key], 1000, callback);
    }
    // resetSMPosition() {
    //     this.noRotate = false
    //     this.resetModelY()
    //     this.animatePosition(this.controls.object.position, this.previousSMPosition, 1000);
    //     this.animatePosition(this.controls.target, this.previousSMTarget, 1000);
    //     // this.controls.object.position.copy(this.previousSMPosition);
    //     // this.controls.target.copy(this.previousSMTarget);
    // }
    setSMPosition() {
        this.noRotate = true
        this.previousSMPosition = this.controls.object.position.clone();
        this.previousSMTarget = this.controls.target.clone();
        let position = new THREE.Vector3(

            0.7793668867572907,
            1.1280849501080417,
            1.3413296230877367);
        this.animatePosition(this.controls.object.position, position, 1000);
        let position1 = new THREE.Vector3(
            1.0340163529508413, 1.0545956302464803, 1.5880348454632205)
        this.animatePosition(this.controls.target, position1, 1000, cb);
        this.setModelY()
    }

    setAPPosition() {
        this.noRotate = true
        this.previousAPPosition = this.controls.object.position.clone();
        this.previousAPTarget = this.controls.target.clone();
        let position = new THREE.Vector3(
            -8.658439674133657, 3.7299442462776553, 3.6158600241840606);
        this.animatePosition(this.controls.object.position, position, 1000);
        let position1 = new THREE.Vector3(
            -1.0620420171465452, -0.05612844384738193, -1.982884844763902)
        this.animatePosition(this.controls.target, position1, 1000);
        this.setModelY()
    }

    initControls() {
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.minDistance = -5; // 设置最小缩放距离为5
        this.controls.maxDistance = 15; // 设置最大缩放距离为20
        this.controls.zoomSpeed = 1.0;
        this.controls.minPolarAngle = Math.PI * .3; // radians
        this.controls.maxPolarAngle = Math.PI / 2.15; // radians
        // this.controls.enableRotate = false;
        this.controls.enablePan = false;
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 1;
        this.controls.object.position.set(-6.081503053750622, 5.129092882431559, -8.694197667661488)
        this.controls.target.set(-0.8838003808545988,
            -0.481387679388669, 1.1720854578141124)
        this.controls.zoom = 1
    }
    initCamera() {
        this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        // this.camera = new THREE.OrthographicCamera(0, 0, 0, 0, 1, 5);
        // debugger
        this.camera.position.set(-9.939, 3.163, -5.644);
        this.camera.lookAt(0, 0, 0);
    }
    initScene() {
        // this.textureLoader = new TextureLoader();
        // this.skyTexture = this.textureLoader.load('./sky.jpg');

        this.scene = new THREE.Scene();

        // this.scene.environment = this.skyTexture;
        // this.scene.background = this.skyTexture;
        this.scene.background = new THREE.Color('rgb(198,224,253)');
        const fogColor = new THREE.Color('rgb(198,224,253)'); // white
        this.scene.fog = new THREE.Fog(fogColor, 0, 60);
        this.addLight();
    }
    initRenderer() {
        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            powerPreference: "high-performance"
        });
        this.renderer.shadowMap.enabled = true;
        this.renderer.outputColorSpace = THREE.SRGBColorSpace;
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.setPixelRatio(Math.min(2, window.devicePixelRatio));
        document.body.appendChild(this.renderer.domElement);
    }
    loadAssets() {
        return new Promise((resolve, reject) => {
            const manager = new THREE.LoadingManager(resolve);

            manager.itemStart("test");
            manager.itemEnd("test");
        });
    }
    addLight() {
        // let light = new THREE.HemisphereLight(0xffffff, 0x444444, 1);
        // light.position.set(0, 10, 0);
        // this.scene.add(light);
        this.scene.add(new THREE.AmbientLight(0xffffff, .6));
        this.scene.add(new THREE.AmbientLight(0x3b90e6, 2));
        const directionalLight0 = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight0.castShadow = true;
        directionalLight0.position.set(-9.947, 10, 12.810);
        this.scene.add(directionalLight0);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight.castShadow = true;
        directionalLight.position.set(5, 10, -3.747);
        this.scene.add(directionalLight);

        const directionalLight1 = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight1.castShadow = true;
        directionalLight1.position.set(5, 10, 14.461);
        this.scene.add(directionalLight1);

        const directionalLight2 = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight2.castShadow = true;
        directionalLight2.position.set(-5.778, 10, -1.120);
        this.scene.add(directionalLight2);
    }
    carAnimate() {
        if (!this.car) return
        // 创建一个Path实例
        if (!this.carPath)
            this.carPath = new THREE.Path([
                new THREE.Vector3(-0.9, -3),
                new THREE.Vector3(-0.9, -6.5),
                new THREE.Vector3(-1.03, -6.5),
                new THREE.Vector3(-1.03, -3),
                new THREE.Vector3(-0.9, -3)
            ]);
        this.carTValue += 0.0005;
        if (this.carTValue > 1) this.carTValue = 0;
        const point = this.carPath.getPointAt(this.carTValue);
        this.car.position.x = point.x;
        this.car.position.y = 0.1;
        this.car.position.z = point.y;


        // 获取路径上的切线
        const tangent = this.carPath.getTangentAt(this.carTValue);

        // 计算汽车应该有的y轴旋转角度
        const angle = Math.atan2(tangent.x, tangent.y);

        // 设置汽车的y轴旋转
        this.car.rotation.y = 1.4 * Math.PI + angle;
    }
    globalParticle() {
        // 创建一个圆形的画布纹理
        const canvas = document.createElement('canvas');
        canvas.width = 64;
        canvas.height = 64;
        const context = canvas.getContext('2d');
        context.beginPath();
        context.arc(32, 32, 32, 0, 2 * Math.PI);
        context.fillStyle = '#007FFF';
        context.fill();

        // 创建一个纹理
        const particleTexture = new THREE.CanvasTexture(canvas);

        // 创建粒子的几何体
        const particles = new THREE.BufferGeometry();
        const particleCount = 1000; // 粒子数量

        // 创建粒子的位置
        // 创建粒子的位置和速度
        const positions = new Float32Array(particleCount * 3);
        const velocities = new Float32Array(particleCount * 3);
        for (let i = 0; i < particleCount * 3; i++) {
            positions[i] = (Math.random() - 0.5) * 20; // 随机位置

            // 为每个粒子分配一个随机速度
            velocities[i] = (Math.random() - 0.5) * 0.02;
        }
        particles.setAttribute('position', new THREE.BufferAttribute(positions, 3));
        particles.setAttribute('velocity', new THREE.BufferAttribute(velocities, 3));

        // 创建粒子的材质
        const particleMaterial = new THREE.PointsMaterial({
            color: '#007FFF', // 金色
            size: 0.025, // 粒子大小
            map: particleTexture,
            transparent: true,
        });

        // 创建粒子系统
        const particleSystem = new THREE.Points(particles, particleMaterial);
        this.globalParticleSystem = particleSystem;
        this.scene.add(particleSystem);
    }
    globalParticleAnimate() {
        if (!this.globalParticleSystem) return
        const positions = this.globalParticleSystem.geometry.attributes.position.array;
        const velocities = this.globalParticleSystem.geometry.attributes.velocity.array;
        for (let i = 0; i < particleCount; i++) {
            positions[i * 3] += velocities[i * 3];
            positions[i * 3 + 1] += velocities[i * 3 + 1];
            positions[i * 3 + 2] += velocities[i * 3 + 2];

            // 如果粒子飘出屏幕，将其重置到初始位置
            if (positions[i * 3 + 1] < -10) {
                positions[i * 3] = (Math.random() - 0.5) * 20;
                positions[i * 3 + 1] = (Math.random() - 0.5) * 20;
                positions[i * 3 + 2] = (Math.random() - 0.5) * 20;
            }
        }
        this.globalParticleSystem.geometry.attributes.position.needsUpdate = true;
    }
    addCar() {
        this.gltfLoader.load('./car.glb', (gltf) => {
            // console.log(gltf)
            const car = gltf.scene;
            car.position.set(0, 0, 0);
            car.scale.set(0.00004, 0.00004, 0.00004);
            this.model.add(car);
            car.rotation.y = 0.4 * Math.PI;
            this.car = car;
            // // 创建一个BufferGeometry实例
            // const geometry = new THREE.BufferGeometry();
            // const y = 0.1
            // // 定义四个点的坐标
            // const vertices = new Float32Array([
            //     -0.86, y, -3, // 第一个点的坐标
            //     -0.86, y, -6.5, // 第二个点的坐标
            //     -1.0, y, -6.5, // 第三个点的坐标
            //     -1.0, y, -3, // 第四个点的坐标
            //     -0.86, y, -3 // 第五个点的坐标，与第一个点相同，用于闭合线段
            // ]);

            // // 将坐标添加到geometry中
            // geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

            // // 创建一个LineBasicMaterial实例
            // const material = new THREE.LineBasicMaterial({
            //     color: 0x0000ff
            // });

            // // 创建一个Line实例
            // const line = new THREE.Line(geometry, material);

            // // 将线添加到场景中
            // this.model.add(line);
            this.carTValue = 0;
        });
    }
    roadParticles() {
        // 创建一个粒子材质
        const particleMaterial = new THREE.PointsMaterial({
            size: 0.01,
            vertexColors: true,
        });

        // 创建一个用于存储粒子位置的数组
        const particles = new Float32Array(1000 * 3); // 1000 particles, 3 coordinates (x, y, z) each
        const particles2 = new Float32Array(1000 * 3); // 1000 particles, 3 coordinates (x, y, z) each
        const particles3 = new Float32Array(1000 * 3);
        const particles4 = new Float32Array(1000 * 3);

        this.particles = particles;
        this.particles2 = particles2;
        this.particles3 = particles3;
        this.particles4 = particles4;

        const colors = new Float32Array(particles.length / 3 * 4);
        const colors2 = new Float32Array(particles2.length / 3 * 4);
        const colors3 = new Float32Array(particles3.length / 3 * 4);
        const colors4 = new Float32Array(particles4.length / 3 * 4);
        const ydef = 0.11;
        // 计算每个粒子的位置
        for (let i = 0; i < particles.length; i += 3) {
            const t = i / particles.length * Math.PI * 2 * 30; // t goes from 0 to 2π * 5
            const r = 0.04; // radius
            const x = i / particles.length * 30 - 15; // spiral along the x-axis, from -15 to 15
            const y = r * Math.sin(t) + ydef; // offset by the y-coordinate of the line
            const z = r * Math.cos(t) - 4.5; // offset by the z-coordinate of the line
            particles[i] = x;
            particles[i + 1] = y;
            particles[i + 2] = z;
            // 随机选择金色或白色
            const color = new THREE.Color(Math.random() > 0.5 ? 0xffff00 : 0xffffff);
            colors[i / 3 * 4] = color.r;
            colors[i / 3 * 4 + 1] = color.g;
            colors[i / 3 * 4 + 2] = color.b;
            colors[i / 3 * 4 + 3] = 1.0;
        }
        for (let i = 0; i < particles2.length; i += 3) {
            const t = i / particles2.length * Math.PI * 2 * 30 + Math.PI; // offset t by π/15 to create a winding effect
            const r = 0.04; // radius
            const x = i / particles2.length * 30 - 15; // spiral along the x-axis, from -15 to 15
            const y = r * Math.sin(t) + ydef; // offset by the y-coordinate of the line
            const z = r * Math.cos(t) - 4.5; // offset by the z-coordinate of the line
            particles2[i] = x;
            particles2[i + 1] = y;
            particles2[i + 2] = z;
            // 随机选择金色或白色
            const color = new THREE.Color(Math.random() > 0.5 ? 0xffff00 : 0xffffff);
            colors2[i / 3 * 4] = color.r;
            colors2[i / 3 * 4 + 1] = color.g;
            colors2[i / 3 * 4 + 2] = color.b;
            colors2[i / 3 * 4 + 3] = 1.0;
        }
        for (let i = 0; i < particles3.length; i += 3) {
            const t = i / particles3.length * Math.PI * 2 * 30 + Math.PI / 2; // offset t by π/2
            const r = 0.04; // radius
            const x = i / particles3.length * 30 - 15; // spiral along the x-axis, from -15 to 15
            const y = r * Math.sin(t) + ydef; // offset by the y-coordinate of the line
            const z = r * Math.cos(t) - 4.5; // offset by the z-coordinate of the line
            particles3[i] = x;
            particles3[i + 1] = y;
            particles3[i + 2] = z;
            // 随机选择金色或白色
            const color = new THREE.Color(Math.random() > 0.5 ? 0xffff00 : 0xffffff);
            colors3[i / 3 * 4] = color.r;
            colors3[i / 3 * 4 + 1] = color.g;
            colors3[i / 3 * 4 + 2] = color.b;
            colors3[i / 3 * 4 + 3] = 1.0;
        }
        for (let i = 0; i < particles4.length; i += 3) {
            const t = i / particles4.length * Math.PI * 2 * 30 + Math.PI * 3 / 2; // offset t by 3π/2
            const r = 0.04; // radius
            const x = i / particles4.length * 30 - 15; // spiral along the x-axis, from -15 to 15
            const y = r * Math.sin(t) + ydef; // offset by the y-coordinate of the line
            const z = r * Math.cos(t) - 4.5; // offset by the z-coordinate of the line
            particles4[i] = x;
            particles4[i + 1] = y;
            particles4[i + 2] = z;
            // 随机选择金色或白色
            const color = new THREE.Color(Math.random() > 0.5 ? 0xffff00 : 0xffffff);
            colors4[i / 3 * 4] = color.r;
            colors4[i / 3 * 4 + 1] = color.g;
            colors4[i / 3 * 4 + 2] = color.b;
            colors4[i / 3 * 4 + 3] = 1.0;
        }

        // 创建一个用于存储粒子位置的属性
        const particleGeometry = new THREE.BufferGeometry();
        const particleGeometry2 = new THREE.BufferGeometry();
        const particleGeometry3 = new THREE.BufferGeometry();
        const particleGeometry4 = new THREE.BufferGeometry();

        particleGeometry.setAttribute('position', new THREE.BufferAttribute(particles, 3));
        particleGeometry2.setAttribute('position', new THREE.BufferAttribute(particles2, 3));
        particleGeometry3.setAttribute('position', new THREE.BufferAttribute(particles3, 3));
        particleGeometry4.setAttribute('position', new THREE.BufferAttribute(particles4, 3));

        particleGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 4));
        particleGeometry2.setAttribute('color', new THREE.BufferAttribute(colors2, 4));
        particleGeometry3.setAttribute('color', new THREE.BufferAttribute(colors3, 4));
        particleGeometry4.setAttribute('color', new THREE.BufferAttribute(colors4, 4));

        const particleSystem = new THREE.Points(particleGeometry, particleMaterial);
        const particleSystem2 = new THREE.Points(particleGeometry2, particleMaterial);
        const particleSystem3 = new THREE.Points(particleGeometry3, particleMaterial);
        const particleSystem4 = new THREE.Points(particleGeometry4, particleMaterial);

        this.particleGeometry = particleGeometry;
        this.particleGeometry2 = particleGeometry2;
        this.particleGeometry3 = particleGeometry3;
        this.particleGeometry4 = particleGeometry4;

        this.model.add(particleSystem);
        this.model.add(particleSystem2);
        this.model.add(particleSystem3);
        this.model.add(particleSystem4);
    }
    roadParticlesAnimate() {
        if (this.particleGeometry === undefined) return;
        if (this.particleGeometry2 === undefined) return;
        if (this.particleGeometry3 === undefined) return;
        if (this.particleGeometry4 === undefined) return;

        const radius = 0.0005;
        for (let i = 0; i < this.particles.length; i += 3) {
            // 生成一个随机角度
            const angle = (performance.now() / 1000 + this.particles[i]) % (Math.PI * 2);


            // 计算偏移量
            const offset_x = Math.cos(angle) * radius;
            const offset_y = Math.sin(angle) * radius;

            // 更新粒子的位置
            this.particles[i] += offset_x;
            this.particles[i + 1] += offset_y;
        }

        for (let i = 0; i < this.particles2.length; i += 3) {
            const angle = (performance.now() / 1000 + this.particles2[i]) % (Math.PI * 2);

            const offset_x = Math.cos(angle) * radius;
            const offset_y = Math.sin(angle) * radius;

            this.particles2[i] += offset_x;
            this.particles2[i + 1] += offset_y;
        }

        for (let i = 0; i < this.particles3.length; i += 3) {
            const angle = (performance.now() / 1000 + this.particles3[i]) % (Math.PI * 2);

            const offset_x = Math.cos(angle) * radius;
            const offset_y = Math.sin(angle) * radius;

            this.particles3[i] += offset_x;
            this.particles3[i + 1] += offset_y;
        }

        for (let i = 0; i < this.particles4.length; i += 3) {
            const angle = (performance.now() / 1000 + this.particles4[i]) % (Math.PI * 2);

            const offset_x = Math.cos(angle) * radius;
            const offset_y = Math.sin(angle) * radius;

            this.particles4[i] += offset_x;
            this.particles4[i + 1] += offset_y;
        }

        this.particleGeometry.attributes.position.needsUpdate = true;
        this.particleGeometry2.attributes.position.needsUpdate = true;
        this.particleGeometry3.attributes.position.needsUpdate = true;
        this.particleGeometry4.attributes.position.needsUpdate = true;
    }
    onClick(event) {
        if (!this.model) return
        // 打印相机的位置
        console.log('Camera position:', `${this.controls.object.position.x},${this.controls.object.position.y},${this.controls.object.position.z}`);

        // 打印控制的目标点
        console.log('Control target:', `${this.controls.target.x},${this.controls.target.y},${this.controls.target.z}`);

        // 打印相机的缩放级别
        // console.log('Camera zoom:', this.controls.object.zoom);
        this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        // 更新射线的方向
        this.raycaster.setFromCamera(this.mouse, this.camera);
        // 计算物体和射线的交点
        const intersects = this.raycaster.intersectObjects(this.model.children, true);
        if (intersects.length > 0) {
            // console.log(intersects[0].point);
            // 如果有交点
            const firstObject = intersects[0].object;

            let currentObject = firstObject;
            while (currentObject) {
                if (currentObject.name === buildings.dc || currentObject.name === buildings.co || currentObject.name === buildings.sm) {
                    if (currentObject.name === buildings.dc) {
                        $('.dc-btn').trigger('click')
                    }
                    if (currentObject.name === buildings.co) {
                        $('.co-btn').trigger('click')
                    }
                    if (currentObject.name === buildings.sm) {
                        $('.sm-btn').trigger('click')
                    }
                    // this.arrows[currentObject.name].visible = true;
                    // this.circles[currentObject.name].visible = true;
                } else {
                    // this.arrows['transform5'].visible = false;
                    // this.arrows['mainDesirefxFBXASC046me_NeoShanghai_BldgLG_E_Roofs'].visible = false;
                    // this.arrows['transform4'].visible = false;
                    // this.circles['transform5'].visible = false;
                    // this.circles['mainDesirefxFBXASC046me_NeoShanghai_BldgLG_E_Roofs'].visible = false;
                    // this.circles['transform4'].visible = false;
                }
                currentObject = currentObject.parent;
            }
        }
    }
    debounce(func, wait) {
        let timeout;
        return function () {
            const context = this, args = arguments;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    }
    checkHover() {
        if (!this.model) return
        this.raycaster1.setFromCamera(this.mouse1, this.camera)

        // 默认将光标样式设置为auto
        this.renderer.domElement.style.cursor = 'auto';
        const intersects = this.raycaster1.intersectObjects(this.model.children, true);
        if (intersects.length > 0) {
            // console.log(intersects[0].point);
            // 如果有交点
            const firstObject = intersects[0].object;

            let currentObject = firstObject;
            while (currentObject) {
                // console.log(currentObject.name)
                if (currentObject.name === buildings.dc || currentObject.name === buildings.co || currentObject.name === buildings.sm) {
                    this.renderer.domElement.style.cursor = 'pointer';
                    for (let key in buildings) {
                        if (buildings[key] === currentObject.name) {
                            this.onHover(key)
                        }
                    }
                    break
                } else {
                    this.allHide()
                }
                currentObject = currentObject.parent;
            }
        }
    }
    render() {
        this.renderer.render(this.scene, this.camera);
    }
    modelRotate() {
        if (this.noRotate) return
        if (this.model)
            this.model.rotation.y += 0.0001;
    }
    tick() {
        if (resizeRendererToDisplaySize(this.renderer)) {
            const canvas = this.renderer.domElement;
            this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
            this.camera.updateProjectionMatrix();
            // this.onResize();
        }
        this.render();
        this.arrowAnimate();
        this.modelRotate();
        this.controls.update();
        // this.roadParticlesAnimate();
        this.carAnimate()
        requestAnimationFrame(this.tick);
    }
}

function resizeRendererToDisplaySize(renderer) {
    const canvas = renderer.domElement;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
        renderer.setSize(width, height, false);
    }
    return needResize;
}
